import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import posed from 'react-pose'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../utils/html-serializer'

import { PictureFluid } from './ui/pictures'

import { rhythm } from '../utils/typography'
import { mq } from '../utils/responsive'
import { linkResolver } from '../utils/link-resolver'

const Artist = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .description {
    flex-grow: 1;
    font-size: ${rhythm(0.7)};
    p {
      text-align: justify;
    }
  }

  ${mq.small(css`
    flex-direction: column;
  `)};
`

const PictureFluidStyledForPose = styled(PictureFluid)`
  margin-left: ${rhythm(1)};
  .gatsby-image-wrapper {
    width: auto;
    margin-bottom: 0;
    background-color: rgba(243, 246, 248, 0.94);
    border: 4px solid #eee;
    box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
      0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50%;
  }

  ${mq.small(css`
    margin-top: ${rhythm(1)};
    margin-left: 0;
  `)};
`

const ArtistPicture = posed(styled.div`
  width: 100%;
  height: 100%;
  max-width: ${p => `${p.fluid.presentationWidth}px`};
  max-height: ${p => `${p.fluid.presentationHeight}px`};
  margin-left: ${rhythm(1)};
  .gatsby-image-wrapper {
    width: auto;
    margin-bottom: 0;
    background-color: rgba(243, 246, 248, 0.94);
    border: 4px solid #eee;
    box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
      0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50%;
  }

  ${mq.small(css`
    margin-top: ${rhythm(1)};
    margin-left: 0;
  `)};
`)({
  hoverable: true,
  init: {
    transform: 'scale(1)',
  },
  hover: {
    transform: 'scale(1.2)',
  },
})

function ArtistDescription({ picture, style, description }) {
  const egkImgFluid = get(picture, 'childImageSharp.fluid')
  return (
    <Artist style={style}>
      <div className="description">
        <RichText
          render={description}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializer}
        />
      </div>
      <ArtistPicture fluid={egkImgFluid}>
        <PictureFluid fluid={egkImgFluid} />
      </ArtistPicture>
    </Artist>
  )
}

export default ArtistDescription
