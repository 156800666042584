import React from 'react'
import { graphql, withPrefix, navigate } from 'gatsby'
import { getUserLangKey } from 'ptz-i18n'

import Index from './_index'

export default props => {
  if (typeof window !== 'undefined') {
    const { langs, defaultLangKey } = props.data.site.siteMetadata.languages
    const langKey = getUserLangKey(langs, defaultLangKey)
    const homeUrl = withPrefix(`/${langKey}/`)

    // I don`t think this is the best solution
    // I would like to use Gatsby Redirects like:
    // https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redirects
    // But Gatsby Redirects are static, they need to be specified at build time,
    // This redirect is dynamic, It needs to know the user browser language.
    // Any ideias? Join the issue: https://github.com/angeloocana/gatsby-starter-default-i18n/issues/4
    navigate(homeUrl)
  }
  return <Index {...props} />
}

export const pageQuery = graphql`
  query {
    site {
      ...SiteInformation
    }
    prismic {
      allHomepages(lang: "en-gb") {
        edges {
          node {
            meta_title
            meta_keywords
            meta_description
            page_subtitle
            content_body
            instagram_title
            _linkType
            _meta {
              id
            }
          }
        }
      }
    }
    egk: file(relativePath: { eq: "egk.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid
          presentationWidth
          presentationHeight
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allInstaNode(limit: 50, sort: { fields: [timestamp], order: DESC }) {
      edges {
        node {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`
