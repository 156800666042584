import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

export const Picture = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${p => `${p.fluid.presentationWidth}px`};
  max-height: ${p => `${p.fluid.presentationHeight}px`};
`

export const PictureFluid = ({ hostRef, fluid, className, style }) => (
  <Picture
    ref={hostRef}
    innerRef={hostRef}
    fluid={fluid}
    className={className}
    style={style}
  >
    <Img fluid={fluid} />
  </Picture>
)
