import React from 'react'
import get from 'lodash/get'
import styled from '@emotion/styled'

import ArtistDescription from '../components/artist-description'
import SocialNetworks from '../components/social-networks'
import { rhythm } from '../utils/typography'

import Layout from '../components/layout'
import { PictureFluid } from '../components/ui/pictures'
import { RichText } from 'prismic-reactjs'

const InstagramContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`
const InstagramTitle = styled.h3`
  font-size: ${rhythm(0.9)};
  text-align: center;
  margin-top: ${rhythm(4)};
  margin-bottom: ${rhythm(2)};
`
const InstagramPost = styled.a`
  flex-basis: 30%;
  margin: 5px;
  border-radius: 5px;
  display: block;
`

function Index({ data }) {
  const {
    meta_title,
    meta_description,
    meta_keywords,
    page_subtitle,
    content_body,
    instagram_title,
  } = get(data, 'prismic.allHomepages.edges[0].node')
  const instaPosts = get(data, 'allInstaNode.edges')
  return (
    <Layout
      title={RichText.asText(meta_title)}
      subtitle={RichText.asText(page_subtitle)}
      site={data.site}
      customKeywords={RichText.asText(meta_keywords)}
      customDescription={RichText.asText(meta_description)}
    >
      <ArtistDescription
        picture={get(data, 'egk')}
        style={{ marginBottom: rhythm(2.5) }}
        description={content_body}
      />
      <SocialNetworks style={{ marginBottom: rhythm(2.5) }} />
      <InstagramTitle>{RichText.asText(instagram_title)}</InstagramTitle>
      <InstagramContent>
        {instaPosts.map(({ node }) => {
          return (
            <InstagramPost
              key={node.id}
              href={'https://www.instagram.com/karalabel/'}
              target={'_blank'}
              rel={'noopener'}
            >
              <PictureFluid
                fluid={get(node, 'localFile.childImageSharp.fluid')}
              />
            </InstagramPost>
          )
        })}
      </InstagramContent>
    </Layout>
  )
}

export default Index
